<template>
  <div>
    <!-- ========== MAIN ========== -->
    <main id="content" role="main" class="bg-light">
      <!-- Breadcrumb Section -->
      <!--<div class="bg-navy" style="background-image: url(../../assets/svg/components/abstract-shapes-20.svg);">-->
      <div class="bg-navy">
        <div class="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div class="row align-items-center">
            <div class="col">
              <div class="d-none d-lg-block">
                <h1 class="h2 text-white">Blog</h1>
              </div>

              <!-- Breadcrumb
            <ol class="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
              <li class="breadcrumb-item">Account</li>
              <li class="breadcrumb-item active" aria-current="page">Teams</li>
            </ol> -->
              <!-- End Breadcrumb -->
            </div>

            <div class="col-auto">
              <div class="d-none d-lg-block">
                <a class="btn btn-sm btn-soft-light" href="#">Log out</a>
              </div>

              <!-- Responsive Toggle Button -->
              <button
                type="button"
                class="navbar-toggler btn btn-icon btn-sm rounde-circle d-lg-none"
                aria-label="Toggle navigation"
                aria-expanded="false"
                aria-controls="sidebarNav"
                data-toggle="collapse"
                data-target="#sidebarNav"
              >
                <span class="navbar-toggler-default">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z"
                    />
                  </svg>
                </span>
                <span class="navbar-toggler-toggled">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                    />
                  </svg>
                </span>
              </button>
              <!-- End Responsive Toggle Button -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Breadcrumb Section -->

      <!-- Content Section -->
      <div class="container space-1 space-top-lg-0 mt-lg-n10">
        <div class="row">
          <div class="col-lg-3">
            <!-- Navbar -->
            <div
              class="navbar-expand-lg navbar-expand-lg-collapse-block navbar-light"
            >
              <div
                id="sidebarNav"
                class="collapse navbar-collapse navbar-vertical"
              >
                <!-- Card -->
                <div class="card mb-5">
                  <div class="card-body">
                    <!-- Avatar -->
                    <div class="d-none d-lg-block text-center mb-5">
                      <div class="avatar avatar-xxl avatar-circle mb-3">
                        <!--<img class="avatar-img" src="../../assets/img/160x160/img1.jpg" alt="Image Description">
                      <img class="avatar-status avatar-lg-status" src="../../assets/svg/illustrations/top-vendor.svg" alt="Image Description" data-toggle="tooltip" data-placement="top" title="Verified user">-->
                      </div>

                      <h4 class="card-title">Natalie Curtis</h4>
                      <p class="card-text font-size-1">natalie@example.com</p>
                    </div>
                    <!-- End Avatar -->

                    <h6 class="text-cap small">Account</h6>

                    <!-- List -->
                    <ul class="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4">
                      <li class="nav-item">
                        <a class="nav-link" href="index.html">
                          <i class="fas fa-id-card nav-icon"></i>
                          Personal info
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="login-and-security.html">
                          <i class="fas fa-shield-alt nav-icon"></i>
                          Login &amp; security
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="notifications.html">
                          <i class="fas fa-bell nav-icon"></i>
                          Notifications
                          <span
                            class="badge badge-soft-navy badge-pill nav-link-badge"
                            >1</span
                          >
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="preferences.html">
                          <i class="fas fa-sliders-h nav-icon"></i>
                          Preferences
                        </a>
                      </li>
                    </ul>
                    <!-- End List -->

                    <h6 class="text-cap small">Shopping</h6>

                    <!-- List -->
                    <ul class="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4">
                      <li class="nav-item">
                        <a class="nav-link" href="orders.html">
                          <i class="fas fa-shopping-basket nav-icon"></i>
                          Your orders
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="wishlist.html">
                          <i class="fas fa-heart nav-icon"></i>
                          Wishlist
                          <span
                            class="badge badge-soft-navy badge-pill nav-link-badge"
                            >2</span
                          >
                        </a>
                      </li>
                    </ul>
                    <!-- End List -->

                    <h6 class="text-cap small">Billing</h6>

                    <!-- List -->
                    <ul class="nav nav-sub nav-sm nav-tabs nav-list-y-2">
                      <li class="nav-item">
                        <a class="nav-link" href="billing.html">
                          <i class="fas fa-credit-card nav-icon"></i>
                          Plans &amp; payment
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="address.html">
                          <i class="fas fa-map-marker-alt nav-icon"></i>
                          Address
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="teams.html">
                          <i class="fas fa-users nav-icon"></i>
                          Teams
                          <span
                            class="badge badge-soft-navy badge-pill nav-link-badge"
                            >+2 new users</span
                          >
                        </a>
                      </li>
                    </ul>
                    <!-- End List -->

                    <h6 class="text-cap small">Blog</h6>

                    <!-- List -->
                    <ul class="nav nav-sub nav-sm nav-tabs nav-list-y-2">
                      <li class="nav-item">
                        <a class="nav-link active" href="teams.html">
                          <i class="fas fa-blog nav-icon"></i>
                          Posts
                        </a>
                      </li>
                    </ul>
                    <!-- End List -->

                    <div class="d-lg-none">
                      <div class="dropdown-divider"></div>

                      <!-- List -->
                      <ul class="nav nav-sub nav-sm nav-tabs nav-list-y-2">
                        <li class="nav-item">
                          <a class="nav-link text-primary" href="#">
                            <i class="fas fa-sign-out-alt nav-icon"></i>
                            Log out
                          </a>
                        </li>
                      </ul>
                      <!-- End List -->
                    </div>
                  </div>
                </div>
                <!-- End Card -->
              </div>
            </div>
            <!-- End Navbar -->
          </div>

          <div class="col-lg-9">
            <form class="mt-3 form-signin" @submit.prevent="editPost(post)">
              <!-- Card -->
              <div class="card">
                <div class="card-header">
                  <div>
                    <b-form-input
                      placeholder="Title"
                      class="bg-transparent text-dark"
                      style="
                        color: #344047;
                        font-weight: 600;
                        border: none;
                        outline: none;
                        font-size: 1.5em;
                      "
                      v-model="post.title"
                    ></b-form-input>
                  </div>
                  <div>
                    <button class="btn btn-sm btn-success mr-2" type="submit">
                      Salvar
                    </button>
                    <router-link
                      class="btn btn-sm btn-danger"
                      :to="'/profile/articles'"
                    >
                      Cancelar
                    </router-link>
                  </div>
                </div>
                <div
                  class="card-body py-0 bg-light"
                  style="border-bottom: 1px solid rgba(0, 0, 0, 0.125)"
                >
                  <b-form-input
                    placeholder="Url Slug"
                    class="bg-transparent text-dark"
                    style="
                      color: #344047;
                      border: none;
                      outline: none;
                      font-size: 0.7em;
                    "
                    v-model="post.slug"
                  ></b-form-input>
                </div>
                <div
                  class="card-body bg-light py-2"
                  style="border-bottom: 1px solid rgba(0, 0, 0, 0.125)"
                >
                  <b-form-input
                    placeholder="Subtitle"
                    class="bg-transparent text-dark"
                    style="
                      color: #344047;
                      font-weight: 600;
                      border: none;
                      outline: none;
                      font-size: 1em;
                    "
                    v-model="post.subTitle"
                  ></b-form-input>
                </div>
                <div class="card-body p-0">
                  <!-- Two-way Data-Binding -->
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="post.body"
                    :options="editorOption"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                  />
                </div>

                <!-- Footer -->
                <div class="card-footer">
                  <b-tabs content-class="mt-3" pills justified>
                    <b-tab active>
                      <template v-slot:title>
                        <i class="fas fa-file"></i> Metadata
                      </template>
                      <div class="row mt-4">
                        <div class="col-12">
                          <b-form-group id="input-group-1" label-for="input-1">
                            <template v-slot:label>
                              <i class="far fa-calendar-alt"></i> Data de
                              publicação
                            </template>
                            <b-form-datepicker
                              id="example-datepicker"
                              v-model="post.post_date"
                              class="mb-2"
                            ></b-form-datepicker>
                          </b-form-group>
                        </div>
                        <!--<div class="col-12">
                        <b-form-group
                          id="input-group-category"
                          label-for="input-category"
                          description="Separe por vírgula."
                        >
                          <template v-slot:label>
                            <i class="fas fa-tags"></i> Categorias
                          </template>
                          <b-form-input
                            id="input-category"
                            v-model="post.categories"
                            placeholder="Modificar aqui o INPUT pelo form-tags"
                          ></b-form-input>
                        </b-form-group>
                      </div>-->
                        <div class="col-12">
                          <b-form-group>
                            <template v-slot:label>
                              <i class="fas fa-tags"></i> Tags
                            </template>
                            <b-form-tags
                              v-model="post.tags"
                              no-outer-focus
                              class="mb-2"
                            >
                              <template
                                v-slot="{ tags, disabled, addTag, removeTag }"
                              >
                                <ul
                                  v-if="tags.length > 0"
                                  class="list-inline d-inline-block mb-2"
                                >
                                  <li
                                    v-for="tag in tags"
                                    :key="tag"
                                    class="list-inline-item"
                                  >
                                    <b-form-tag
                                      @remove="removeTag(tag)"
                                      :title="tag"
                                      :disabled="disabled"
                                      variant="info"
                                      >{{ tag }}</b-form-tag
                                    >
                                  </li>
                                </ul>

                                <b-dropdown
                                  size="sm"
                                  variant="outline-secondary"
                                  block
                                  menu-class="w-100"
                                >
                                  <template v-slot:button-content>
                                    <b-icon icon="tag-fill"></b-icon> Selecione
                                    a Tag
                                  </template>
                                  <b-dropdown-form
                                    @submit.stop.prevent="() => {}"
                                  >
                                    <b-form-group
                                      label-for="tag-search-input"
                                      label="Search tags"
                                      label-cols-md="auto"
                                      class="mb-0"
                                      label-size="sm"
                                      :description="searchDesc"
                                      :disabled="disabled"
                                    >
                                      <b-form-input
                                        v-model="search"
                                        id="tag-search-input"
                                        type="search"
                                        size="sm"
                                        autocomplete="off"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-dropdown-form>
                                  <b-dropdown-divider></b-dropdown-divider>
                                  <b-dropdown-item-button
                                    v-for="option in availableOptions"
                                    :key="option"
                                    @click="onOptionClick({ option, addTag })"
                                  >
                                    {{ option }}
                                  </b-dropdown-item-button>
                                  <b-dropdown-text
                                    v-if="availableOptions.length === 0"
                                  >
                                    There are no tags available to select
                                  </b-dropdown-text>
                                </b-dropdown>
                              </template>
                            </b-form-tags>
                          </b-form-group>
                        </div>
                        <div class="col-12">
                          <b-form-group
                            id="input-group-seo-title"
                            label="SEO Title:"
                            label-for="input-seo-title"
                            description="Incluir alguma ajuda aqui."
                          >
                            <template v-slot:label>
                              <i class="far fa-file-alt"></i> Resumo
                            </template>
                            <b-form-textarea
                              id="textarea-rows"
                              rows="2"
                              v-model="post.summary"
                            ></b-form-textarea>
                          </b-form-group>
                        </div>
                        <div class="col-12">
                          <b-form-group
                            id="input-group-1"
                            label-for="input-1"
                            description="Incluir o visualizador da imagem."
                          >
                            <template v-slot:label>
                              <i class="far fa-image"></i> Imagem em destaque
                            </template>
                            <b-form-file
                              id="file-small"
                              size="sm"
                              v-model="post.file"
                            ></b-form-file>
                          </b-form-group>
                        </div>
                        <div class="col-12">
                          <b-form-group
                            id="input-group-imageAlt"
                            label-for="input-imageAlt"
                            description="Descrição da imagem em destaque."
                          >
                            <template v-slot:label>
                              <i class="fas fa-tag"></i> Texto da imagem em
                              destaque
                            </template>
                            <b-form-input
                              id="input-imageAlt"
                              v-model="post.image_alt"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <i class="fas fa-search"></i> SEO
                      </template>
                      <div class="row">
                        <div class="col-12">
                          <b-form-group
                            id="input-group-seo-title"
                            label="SEO Title:"
                            label-for="input-seo-title"
                            description="We suggest a max of 55 chars."
                          >
                            <b-form-input
                              id="input-seo-title"
                              v-model="post.seo_title"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-12">
                          <b-form-group
                            id="input-group-meta-description"
                            label="Meta Descrição:"
                            label-for="input-meta-description"
                            description="We suggest a max of 140 chars."
                          >
                            <b-form-textarea
                              id="textarea-rows"
                              rows="5"
                              v-model="post.meta_description"
                            ></b-form-textarea>
                          </b-form-group>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
                <!-- End Footer -->
              </div>
              <!-- End Card -->
            </form>
          </div>
        </div>
        <!-- End Row -->
      </div>
      <!-- End Content Section -->
    </main>
    <!-- ========== END MAIN ========== -->
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  name: "EditPost",
  data() {
    return {
      id: this.$route.params.id,
      // Form Tags
      options: ["Simulação Habitacional", "Crédito", "Consórcio", "FGTS"],
      search: "",
      created: "",
      editorOption: {}
    };
  },
  components: {
    quillEditor,
  },
  computed: {
    ...mapState(["post"]),
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      // Filter out already selected options
      const options = this.options.filter(
        (opt) => this.post.tags.indexOf(opt) === -1
      );
      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.toLowerCase().indexOf(criteria) > -1
        );
      }
      // Show all options available
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
  watch: {
    'post.created'(newVal){
      this.created = newVal.toDate
    }
  },
  async created() {
    //await this.getPost(this.id);
    await this.getPostForEdit(this.id);
  },
  mutations: {
    ...mapMutations(["setPost"]),
  },
  methods: {
    ...mapActions(["getPostForEdit", "editPost", "dismissed"]),
    onOptionClick({ option, addTag }) {
      addTag(option)
      this.search = "";
    },
  },
};
</script>
